<template>
	<div>
		<f-default-header
			title="Marketing"
			subtitle="Suporte" />

		<f-card>
			<v-row>
				<v-col cols="4">
					<v-switch
						:disabled="false"
						hide-details
						v-model="contact.answered"
						label="Respondido" />
					<br>
				</v-col>
				<v-col cols="8" />
			</v-row>
			<v-row>
				<v-col cols="12">
					<f-card-title>Dados</f-card-title>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					Criado em:<br />
					<strong>{{contact.created_at}}</strong><br />
					<br />
					Nome:<br />
					<strong>{{contact.nome}}</strong><br />
					<br />
					Email:<br />
					<strong>{{contact.email}}</strong><br />
					<br />
					Mensagem:<br />
					<strong>{{contact.message}}</strong><br />
					<div v-if="contact.file">
						<br />
						Arquivo anexo:<br />
						<a
							:href="ENVS.API_URL + 'contact/' + contact.file"
							target="_blank">
							{{ENVS.API_URL}}contact/{{contact.file}}
						</a><br>
					</div>
				</v-col>
			</v-row>
		</f-card>

		<f-actions-btn-holder>
            <v-btn
                color="secondary"
                elevation="0"
                large
                @click="back"
                :disabled="false">

                cancelar
            </v-btn>
            &nbsp;
            <v-btn
                color="primary"
                elevation="0"
                large
                @click="save"
                :disabled="false">

                {{true?'SALVAR':'salvando'}}
            </v-btn>
        </f-actions-btn-holder>
	</div>
</template>

<script>
import ENVS from '../../../../env'

export default {
	data: () => ({
		id: null,
		contact: {},
		ENVS
	}),
	created() {
		this.id = this.$route.params.id
		this.getData()
	},
	methods: {
		async getData() {
            this.$store.dispatch( 'system/loading', 'data' )
            const contact = await this.$store.dispatch( 'mkt/contact_get', this.id  )
            this.$store.dispatch( 'system/loading', 'done' )

			this.contact = contact
        },
		async save(){
			this.$store.dispatch( 'system/loading', 'data' )
            await this.$store.dispatch(
				'mkt/contact_save',
				this.contact
			)
            this.$store.dispatch( 'system/loading', 'done' )
            this.back()
        },
        back(){
            this.$router.push({
                name: 'mkt-contact-list'
            })
        }
	}
}
</script>